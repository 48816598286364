import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

export const SifreSifirlamaGonderildi = () => {
    const navigate = useNavigate();

    useEffect(() => {
      setTimeout(() => {
        navigate('/oturum-ac')
      }, 2033)
    }, []);

  return (
    <>
<div className="container w-full mt-11 max-w-sm p-6 pt-3 mx-auto bg-gray-100 rounded-lg shadow-md">
    <div className="flex justify-center mx-auto mt-10">
    </div>

    <div className="mt-5">
            <p className="text-xl text-gray-800 mb-5">Şifre sıfırlama bağlantısı e-posta adresinize gönderildi.</p>
            <p>Anasayfaya yönlendiriliyorsunuz...</p>
        
        </div>
</div>
    </>
  );
};