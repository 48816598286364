import React, { useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { usePocket } from "../contexts/PocketContext";

export const SifreSifirlama = () => {
  const emailRef = useRef();
  const { yenileme } = usePocket();
  const navigate = useNavigate();

  const handleOnSubmit = useCallback(
    async (evt) => {
      evt?.preventDefault();
      await yenileme(emailRef.current.value);
      navigate("/sifre-sifirlama-gonderildi");
    },
    [yenileme]
  );

  return (
    <>
<div className="container w-full mt-11 max-w-sm p-6 pt-3 mx-auto bg-gray-100 rounded-lg shadow-md">
    <div className="flex justify-center mx-auto mt-10">
        <h2 className="font-bold text-3">RIHLE Uzaktan Eğitim Merkezi</h2>
    </div>

    <form onSubmit={handleOnSubmit} className="mt-6">
        <div className="mt-4">
              <label className="block text-sm text-gray-800">E-Posta Adresiniz
            <input id="email" type="email" ref={emailRef} className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border rounded-lg focus:border-blue-400  focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40" /></label>
        </div>

        <div className="mt-6">
            <button type="submit" className="w-full px-6 py-2.5 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#191730] rounded-lg hover:bg-gray-700 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-50">
                Şifreyi Sıfırla
            </button>
        </div>
        <div className="mt-5">
            <p className="text-sm text-gray-800 mb-5">Aşağıdaki kutucuğa kayıtlı e-posta adresinizi girip, şifrenizi sıfırlayabilirsiniz. E-posta adresinize iletilen linke 24 saat içinde tıklamanız gerekmektedir.</p>
            <p className="text-sm text-gray-800 mb-5">Eğer size iletilen bağlantı zaman aşımına uğrarsa buradan tekrar şifre sıfırlama talebinde bulunabilirsiniz.</p>
            <p className="text-sm text-gray-800">E-Postanın size ulaşmaması halinde WhatsApp üzerinden bizimle irtibata geçebilirsiniz.</p>

        </div>
    </form>
</div>
    </>
  );
};