import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { OturumAc } from "./pages/OturumAc";
import { RequireAuth } from "./components/RequireAuth";
import { PocketProvider } from "./contexts/PocketContext";
import { Anasayfa } from "./pages/Anasayfa";
import { MezheplerTarihi201 } from "./pages/dersler/MezheplerTarihi201";
import { MezheplerTarihi101 } from "./pages/dersler/MezheplerTarihi101";
import { Fadile300 } from "./pages/dersler/Fadile300";
import { Akaid200 } from "./pages/dersler/Akaid200";
import { Akaid100 } from "./pages/dersler/Akaid100";
import { DinlerTarihi102 } from "./pages/dersler/DinlerTarihi102";
import { Hadis103 } from "./pages/dersler/Hadis103";
import { HadisUsulu104 } from "./pages/dersler/HadisUsulu104";
import { NotFound } from "./pages/NotFound";
import { NotRegister } from "./pages/403";
import { SifreSifirlama } from "./pages/SifreSifirlama";
import { SifreSifirlamaGonderildi } from "./pages/SifreSifirlamaGonderildi";
import './output.css'

export const App = () => {
  console.clear()
  console.log('بِسْمِ اللهِ الرَّحْمٰنِ الرَّحِيمِ')
  console.log("Sadece şu iki kimseye gıpta edilir: Biri Allah'ın kendisine Kur’ân verdiği ve gece gündüz onunla meşgul olan kimse, diğeri Al­lah'ın kendisine mal verdiği ve bu malı gece gündüz O'nun yolunda harcayan kimse.")
  console.log('Buhârî, İlim 15.')
  return (
    <div className="app">
    <PocketProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/oturum" element={<OturumAc />} />
          <Route path="/sifre-sifirlama" element={<SifreSifirlama />} />
          <Route path="/sifre-sifirlama-gonderildi" element={<SifreSifirlamaGonderildi />} />
          <Route element={<RequireAuth />}>
            <Route path="/" element={<Anasayfa />} />
            <Route path="/201-mezhepler-tarihi" element={<MezheplerTarihi201 />} />
            <Route path="/200-akaid/" element={<Akaid200 />} />
            <Route path="/100-akaid/" element={<Akaid100 />} />
            <Route path="/101-mezhepler-tarihi" element={<MezheplerTarihi101 />} />
            <Route path="/102-dinler-tarihi" element={<DinlerTarihi102 />} />
            <Route path="/103-hadis/" element={<Hadis103 />} />
            <Route path="/104-hadis-usulu/" element={<HadisUsulu104 />} />
            <Route path="/300-fadile/" element={<Fadile300 />} />
            <Route path="/403-kayitli-degilsiniz/" element={<NotRegister />} />

            <Route
                    path="*"
                    element={<NotFound />}
                />
          </Route>
        </Routes>
      </BrowserRouter>
    </PocketProvider>
    </div>
  );
};
