import {
  useState,
  useEffect,
  useMemo,
} from "react";
import PocketBase from "pocketbase";
import {Header} from "../../components/Header"
import {Footer} from "../../components/Footer"

const BASE_URL = "https://kitabiyat.ruzem.org";

export const DinlerTarihi102 = ({ children }) => {
  const pb = useMemo(() => new PocketBase(BASE_URL), []);
  const [dersismi, setData] = useState(null);
  const [dersayrinti, setAyrinti] = useState(null);

  pb.autoCancellation(false);
  useEffect(() => {

    const Ders = () => {
      return pb.collection('arsivdersleri').getFullList({
        sort: "-created",
        filter: "ders = '102b05o570le46y'"
      }
      );
    };
    Ders().then((result) => {
      setData(result);
    });

    const dersAyrinti = () => {
      return pb.collection('kayitlidersler').getFullList({
        filter: "id = '102b05o570le46y'"
      }
      );
    };
    dersAyrinti().then((result) => {
      setAyrinti(result);
    });
  }, []);

  const listDersIsimleri = dersismi && dersismi.map(person =>
    <tr className="text-right border-2 border-[#bbbac2]" key={person.id}>
      <td className="px-3 py-2 text-left text-sm">
        <span>{person.baslik}</span>
      </td>
      <td className="px-3 py-2 text-left text-sm">
        <span>{person.derstarihi}</span>
      </td>
      <td className="px-3 py-2 text-left text-sm">
        <span>{person.derssuresi}</span>
      </td>
      <td className="px-3 py-2" >
        <button type="button" className="px-8 py-3 font-semibold text-sm text-[#7C798F] rounded">
          <a target="_blank" href={'https://uzaktanegitim.istanbul/playback/presentation/2.3/' + person.bbbid}>İzle <span className="text-base">↗️</span></a>
        </button>
      </td>
    </tr>
  );

  const listAyrinti = dersayrinti && dersayrinti.map(ayrinti =>
    <div key={ayrinti.id}>
      <div className="col-end-7 row-span-1 col-span-2">
        <div className="max-w-2xl px-8 py-4 bg-white rounded-lg mb-3 shadow-md">
          <div className="items-center justify-between">
            <span className="text-sm font-light text-[#9290A1]">Son İlân</span>
          </div>
          <div className="mt-2">
            <p className="text-xl font-bold text-[#9290A1]" tabIndex="0">{ayrinti.sonilanbaslik}</p>
            <p className="mt-2 text-[#9290A1]">{ayrinti.sonilanaciklama}</p>
        </div>
        </div>
      </div>
      <div className="col-end-7 col-span-2">
        <div className="max-w-2xl px-8 py-4 bg-white rounded-lg shadow-md">
          <div className="items-center justify-between">
            <span className="text-sm font-light text-[#9290A1]">Dönem: {ayrinti.donem}</span>
            <a className="px-3 py-1 ml-3 text-sm font-bold text-gray-100 transition-colors duration-300 transform bg-gray-600 rounded cursor-pointer hover:bg-gray-500" tabIndex="0" role="button" href="#">{ayrinti.status}</a>
        </div>
        <div className="mt-2">
          <p className="text-xl font-bold text-[#9290A1]" tabIndex="0">{ayrinti.baslik}</p>
          <p className="mt-2 text-[#9290A1]">{ayrinti.aciklama}</p>
        </div>
        </div>
      </div>
  </div>
);

return (    
  <>
  <Header/>
  <div className="w-11/12 mx-auto row-span-3 lg:grid lg:grid-cols-3 sm:grid sm:grid-cols-1 gap-4">
    <div className="col-start-1 col-end-3 rounded-lg">
        <div className="p-2 sm:p-4 bg-white">
          <h2 className="mb-3 text-2xl text-[#9290A1] font-semibold leading-tight">Arşiv Dersleri</h2>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-[#E9E8EC]">
                <thead className="rounded-t-lg">
                  <tr className="bg-[#BDBCC7] border-2 border-solid border-[#bbbac2]">
                    <th title="Ders Başlığı" className="p-3 text-[#7C798F] text-left text-sm">Ders Başlığı</th>
                    <th title="Tarih" className="p-3  divide-x text-[#7C798F] text-left text-sm">Tarih</th>
                    <th title="Tarih" className="p-3  divide-x text-[#7C798F] text-left text-sm">Toplam Süre</th>
                    <th title="Şimdi İzle" className="p-3  divide-x text-[#7C798F] text-left text-sm"></th>
                  </tr>
                </thead>
                <tbody>
                  {listDersIsimleri}
                </tbody>
              </table>
            </div>
          </div>
    </div>
    {listAyrinti}
  </div>
  <Footer />
  </>
);
};