import {
  useState,
  useEffect,
  useMemo,
} from "react";
import PocketBase from "pocketbase";
import { usePocket } from "../contexts/PocketContext";
const BASE_URL = "https://kitabiyat.ruzem.org";

export const Header = ({ children }) => {
  const { logout } = usePocket();
  const pb = useMemo(() => new PocketBase(BASE_URL), []);
  const [ikaz, setIkaz] = useState(null);
  const [kayitliDersler, setKayitliDersler] = useState(null);

  pb.autoCancellation(false);

  useEffect(() => {

    const kayitlar = () => {
      return pb.collection('users').getFullList({
        expand: 'kayitlidersler',
        sort: 'kayitlidersler'
    });
    };
    
    kayitlar().then((result) => {
      setKayitliDersler(result);
    })

    const Ikaz = () => {
      return pb.collection('ikaz').getFullList({
        filter: 'ikaz = true',
      });
    };
    Ikaz().then((result) => {
      setIkaz(result);
    })
  }, []);
  function YetkiDers() {
    if (kayitliDersler && kayitliDersler[0]?.dogrulanmis) {
      const listDersler = kayitliDersler && kayitliDersler?.map((dersler) =>
        { 
          return (dersler.expand.kayitlidersler
            .map((ders) =>
            <button className="flex flex-wrap items-right items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#191730] rounded-lg hover:bg-[#A7A6B4] focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80 mr-3 ml-3 mb-3" key={ders.id}>
              <a rel="noopener noreferrer" href={ders.adres} className="mx-1">{ders.baslik}</a>  
            </button>
          )
          )
        }
  ) 
  return listDersler
}

if (kayitliDersler && kayitliDersler[0]?.dogrulanmis === false) {

  return window.location = '/403-kayitli-degilsiniz'
}
}

const ikazKullanabilir = ikaz && ikaz.map((ikazlist) =>
  { const ikazKullanimi = ikazlist.ikaz
    return ikazKullanimi
  }
);

function Ikaz() {
  if (ikazKullanabilir && ikazKullanabilir.length > 0) {
    const ikazList = kayitliDersler && kayitliDersler.map(ikaz =>
      <div className="container items-center text-center justify-between px-6 py-4  mx-auto" key={ikaz.collectionId}>
              <div className="flex mr-7 text-center">
                  <span className="text-center ml-11 mr-7 h-full w-ful animate-ping text-7lg font-black">CANLI</span>
      
                  <p className="text-center"><a href={ikaz.canlidersadres}>Mezhepler Tarihi canlı dersi başladı. Derse giriş için buraya tıklayabilirsiniz.</a></p>
              </div>
          </div>
      );
    return ikazList
  }
}
  return (
   <>
      <nav className="bg-[#252145] shadow">
        <div className="container items-center w-3/4 justify-left p-6 mx-auto capitalize flex flex-wrap">
          {YetkiDers()}
        <button onClick={logout} className="flex flex-wrap items-right items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#191730] rounded-lg hover:bg-[#A7A6B4] focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80 mr-3 ml-3 mb-3">
          <span className="mx-1">Çıkış Yap</span>
        </button>
        </div>
      </nav>
      <div className="w-full text-white bg-red-500 mb-10">
      {Ikaz()}
</div>

    </>
  )};
