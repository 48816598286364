
  import {useLocation} from "react-router-dom"

  
  export const NotFound = ({ children }) => {


    function NotFoundLocation() {
        let location = useLocation();
      
        return (
<section class="bg-white dark:bg-gray-900 ">
    <div class="container min-h-screen px-6 py-12 mx-auto lg:flex lg:items-center lg:gap-12">
        <div class="wf-ull lg:w-1/2">
            <p class="text-sm font-medium text-blue-500 dark:text-blue-400">404 Hatası</p>
            <h1 class="mt-3 text-2xl font-semibold text-gray-800 dark:text-white md:text-3xl">Sayfa bulunamadı.</h1>
            <p class="mt-4 text-gray-500 dark:text-gray-400">Ulaşmaya çalıştığınız sayfa maalesef mevcut değil.</p>

            <div class="flex items-center mt-6 gap-x-3">
                <button class="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 rtl:rotate-180">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                    </svg>


                    <span><a href="/">Anasayfaya Dön</a></span>
                </button>
            </div>
        </div>

        <div class="relative w-full mt-8 lg:w-full lg:mt-0">
            <img class=" w-full lg:h-[32rem] h-80 md:h-96 rounded-lg object-cover " src="https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1828000/buyuk-camlica-camii-a-1828580.jpg" alt="" />
        </div>
    </div>
</section>
        );
      }
    return (
     <> 
        {NotFoundLocation()}
      </>
    )};
  