import React, { useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { usePocket } from "../contexts/PocketContext";

export const OturumAc = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login, googleLogin, error } = usePocket();
  const navigate = useNavigate();

  const handleOnSubmit = useCallback(
    async (evt) => {
      evt?.preventDefault();
      await login(emailRef.current.value, passwordRef.current.value);
      navigate("/")
    },
    [login]
  );

  function Kontrol () {
    if (error) {
    const hataMesaji = (<div className="flex w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-md">
          <div className="flex items-center justify-center w-12 bg-red-500">
              <svg className="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
              </svg>
          </div>
      
          <div className="px-4 py-2 -mx-3">
              <div className="mx-3">
                  <span className="font-semibold text-red-500">Hata</span>
                  <div className="text-sm text-gray-600">
                      Giriş Bilgileriniz ya da Parolanız Hatalı, sayfayı yenileyerek tekrar deneyiniz.
                  </div>
              </div>
          </div>
      </div>)
    return hataMesaji
 }};

  const handleOnGoogle = useCallback(
    async (evt) => {
      evt?.preventDefault();
      await googleLogin();
      navigate("/");
    },
    [googleLogin]
  );


  return (
    <>
<div className="container w-full mt-7 max-w-sm p-6 pt-3 mx-auto bg-gray-100 rounded-lg shadow-md">
    <div className="flex justify-center mx-auto mt-10">
        <h2 className="font-bold text-3">RIHLE Uzaktan Eğitim Merkezi</h2>
    </div>

    <form onSubmit={handleOnSubmit} className="mt-6">
        <div>
            <label className="block text-sm text-gray-800">Talebe ID / E-Posta Adresi</label>
            <input id="eposta" ref={emailRef} type="text" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border rounded-lg focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
        </div>

        <div className="mt-4">
                <label className="block text-sm text-gray-800">Şifre (<a href="sifre-sifirlama" className="text-l text-gray-500 hover:underline">Şifrenizi mi unuttunuz?</a>)</label>
            <input id="sifre" type="password" ref={passwordRef} className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border rounded-lg focus:border-blue-400  focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40" />
        </div>
        {Kontrol()}
        <div className="mt-6">
            <button type="submit" className="w-full px-6 py-2.5 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#191730] rounded-lg hover:bg-gray-700 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-50">
                Oturum Aç
            </button>
        </div>
    </form>

    <div className="flex items-center justify-between mt-4">
        <span className="w-1/5 border-b lg:w-1/5"></span>

        <a href="javascript:void(0)" className="text-xs text-center text-gray-500 uppercase hover:underline">
            Diğer Hesaplarınız ile Giriş Yapabilirsiniz
        </a>

        <span className="w-1/5 border-b  lg:w-1/5"></span>
    </div>

    <form onSubmit={handleOnGoogle} className="flex items-center mt-6 -mx-2">
        <button type="submit" className="flex items-center justify-center w-full px-6 py-2 mx-2 text-sm font-medium text-white transition-colors duration-300 transform bg-blue-500 rounded-lg hover:bg-blue-400 focus:bg-blue-400 focus:outline-none">
            <svg className="w-4 h-4 mx-2 fill-current" viewBox="0 0 24 24">
                <path d="M12.24 10.285V14.4h6.806c-.275 1.765-2.056 5.174-6.806 5.174-4.095 0-7.439-3.389-7.439-7.574s3.345-7.574 7.439-7.574c2.33 0 3.891.989 4.785 1.849l3.254-3.138C18.189 1.186 15.479 0 12.24 0c-6.635 0-12 5.365-12 12s5.365 12 12 12c6.926 0 11.52-4.869 11.52-11.726 0-.788-.085-1.39-.189-1.989H12.24z">
                </path>
            </svg>
            <span className="mx-2 sm:inline">Google'la Oturum Aç</span>
        </button>

    </form>
    <div className="mt-8 text-xs font-light text-center text-gray-400"> Problemle mi karşılaştınız?  <a href="https://wa.me/902125315030" target="_blank" className="font-medium text-gray-700 hover:underline">WhatsApp'tan Bize Ulaşabilirsiniz.</a></div>
</div>
<div className="max-w-2xl px-8 py-4 bg-white rounded-lg shadow-md mx-auto mt-3">
    <div className="mt-2">
        <a href="#" className="text-xl font-bold text-gray-700 hover:text-gray-600 hover:underline" tabindex="0" role="link">Yeni Arayüz Hakkında</a>
        <p className="mt-2 text-gray-600">Daha önce kullanılan ÖYS sistemimizi, kendimizce geliştirilen, yeni arayüzle değiştirmeye karar verdik. Yeni arayüzde arşiv derslerinize hızlıca erişebilir, canlı ders saatinde sayfanın üstünde yer alacak bildirim ile canlı derse hızlıca geçiş yapabilirsiniz. Yeni arayüzümüz kendi kaynaklarımız ile geliştirilmeye devam ediliyor. Problemle karşılamanız halinde şimdiden özür diler, probleminizi bize iletmenizi rica ederiz. Daha önce kullandığınız şifre sıfırlanıp, yeni şifreniz e-posta adresinize iletildi. Eğer tekrar şifre almak isterseniz, <a href="sifre-sifirlama" className="text-l text-red-900 underline">şifremi unuttum</a> adresine tıklayabilirsiniz.</p>
    </div>
</div>
    </>
  );
};