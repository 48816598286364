export const Footer = ({ children }) => {

  return (
   <> 
    <footer className="bg-[#252145] mt-11">
      <div className="mx-auto text-center">
        <h1 className="text-7xl font-black mx-auto text-[#121022] pt-3">RUZEM</h1>
        <p className="text-sm text-[#fffefe] pb-3">© Öğrenim Yönetim Sistemi. Version: 1.0.0</p>
      </div>
</footer>
      

    </>
  )};
