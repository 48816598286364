import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import './output.css'

ReactDOM.createRoot(document.querySelector("#root")).render(
 <React.StrictMode>
   <App />
 </React.StrictMode>
);

