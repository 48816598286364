import {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from "react";
import PocketBase from 'pocketbase';
import { useInterval } from "usehooks-ts";
import {jwtDecode} from "jwt-decode";
import ms from "ms";

const BASE_URL = "https://kitabiyat.ruzem.org";
const fiveMinutesInMs = ms("5 minutes");
const twoMinutesInMs = ms("2 minutes");
const PocketContext = createContext({});

export const PocketProvider = ({ children }) => {
  const pb = useMemo(() => new PocketBase(BASE_URL), []);
  pb.autoCancellation(false);
  const [token, setToken] = useState(pb.authStore.token);
  const [user, setUser] = useState(pb.authStore.model);
  const [error, setError] = useState(pb.authStore.token);

  useEffect(() => {
    return pb.authStore.onChange((token, model, error) => {
      setToken(token);
      setUser(model);
      setError(error);
    });
  });

  const register = useCallback(async (email, password) => {
    return await pb
      .collection("users")
      .create({ email, password, passwordConfirm: password });
  }, []);

  const yenileme = useCallback(async (email) => {
    return await pb
      .collection("users")
      .requestPasswordReset(email);
  }, []);

    const login = useCallback(async (email, password) => {
        return await pb.collection("users").authWithPassword(email, password).catch(error => setError(true))
      
    }, []);

  const kayitliDersler = useCallback(async () => {
    return await pb.collection("users").getFullList({
      expand: 'kayitlidersler',
  });
  }, []);

  kayitliDersler()

  const googleLogin = useCallback(async () => {
    return await pb.collection("users").authWithOAuth2({ provider: 'google' });

  }, []);

  const appleLogin = useCallback(async () => {
    return await pb.collection("users").authWithOAuth2({ provider: 'facebook' });

  }, []);

  const logout = useCallback(() => {
    pb.authStore.clear();
  }, []);

  const refreshSession = useCallback(async () => {
    if (!pb.authStore.isValid) return;
    const decoded = jwtDecode(token);
    const tokenExpiration = decoded.exp;
    const expirationWithBuffer = (decoded.exp + fiveMinutesInMs) / 1000;
    if (tokenExpiration < expirationWithBuffer) {
      await pb.collection("users").authRefresh();
    }
  }, [token]);

  useInterval(refreshSession, token ? twoMinutesInMs : null);

  return (
    <PocketContext.Provider
      value={{ register, login, logout, error, user, token, pb, yenileme, googleLogin, appleLogin, kayitliDersler }}
    >
      {children}
    </PocketContext.Provider>
  );
};

export const usePocket = () => useContext(PocketContext);
