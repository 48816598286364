import {
  useState,
  useEffect,
  useMemo,
} from "react";
import PocketBase from "pocketbase";
import {Header} from "../components/Header"
import {Footer} from "../components/Footer"

const BASE_URL = "https://kitabiyat.ruzem.org";

export const Anasayfa = ({ children }) => {

  const pb = useMemo(() => new PocketBase(BASE_URL), []);
  const [talebe, setTalebe] = useState(null);
 
  pb.autoCancellation(false);

  useEffect(() => {
    const Talebe = () => {
      return pb.collection('users').getFullList({
        expand: 'kayitlidersler',
    });
    };
    Talebe().then((result) => {
      setTalebe(result);
    });
  }, []);

  const listTalebe = talebe && talebe.map(talebe =>
    <h1 className="text-3xl font-semibold text-left text-[#252145] lg:text-4xl" key={talebe.collectionId}>Selamun Aleyküm {talebe.name}.</h1>
  );
  
  return (    
    <>
    <Header/>
    <div className="max-w-2xl px-8 py-8 mx-auto 4 bg-white rounded-lg shadow-md">
      <div className="mt-2 text-[#252145]">
          {listTalebe}
        <p className="mt-2 text-gray-600">RUZEM'e hoşgeldiniz. Yukarıdaki menüden kayıtlı olduğunuz derslere erişebilir, arşiv videolarınızı izleyebilirsiniz. Eğer canlı derslere kayıtlı iseniz, ders başlamadan önce yukarıda bir bildirim yer alacak. Bildirime tıklayıp, canlı derse giriş yapabilirsiniz.</p>
      </div>
    </div> 
    <Footer />
    </>
  );
};